import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';
import { CommonService } from '../shared-service/common.service';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';


var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
    'authorization': `${localStorage.getItem('TOTOU_accessToken')}`
  })
};
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': `${localStorage.getItem('TOTOU_accessToken')}`
    })
  };
}

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  getTourPlanURL = 'getTourplan';
  getContentURL = "getContentDtl";
  insTourURL = "addTourData ";

  constructor(private http:HttpClient,private appService:CommonService) { }

  getContentDetails(body): Observable<any> {
    const url = this.appService.adminBaseUrl + this.getContentURL;
    return this.http.post(url,body,httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  
  getAdmintourPlant(): Observable<any> {
    const url = this.appService.adminBaseUrl + this.getTourPlanURL;
    return this.http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }

  insertTourDetails(body): Observable<any> {
    const url = this.appService.adminBaseUrl + this.insTourURL;
    return this.http.post(url,body,httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }


  // ===================================== Exception Handler ===========================================
  private handleError(error: HttpErrorResponse) {
    var errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      //A client-side or network error occurred. Handle it accordingly.
      errorMsg = `An error occurred: ${error.error.message}`;
    } else {
      //The backend returned an unsuccessful response code.
      //The response body may contain clues as to what went wrong,
      errorMsg = `Backend returned code ${error.status}`;
    }
    //return an observable with a user-facing error message
    return throwError(errorMsg);
  }
}
