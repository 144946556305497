import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared-service/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-age-verification',
  templateUrl: './age-verification.component.html',
  styleUrls: ['./age-verification.component.scss']
})
export class AgeVerificationComponent implements OnInit {
  checkitem = false;
  ageVerificationDesc:any;
 

  constructor(private readonly appService: CommonService,private readonly router:Router) {
    
  }

  ngOnInit() {
    if (localStorage.getItem("viewReserFlag") == "view" && localStorage.getItem("uniqkeyFromReservation") != null) {
      let details = JSON.parse(localStorage.getItem('resvResponse'))
      console.log("details", details);
      if (details != null) {
        let plantId = details[0].toy_tour_plnt_id
        let getplantsResponse = JSON.parse(localStorage.getItem("plantDetailsfromView"))
        console.log("getplantsResponse",getplantsResponse);
        for (let i = 0; i < getplantsResponse.length; i++) {
          if (plantId == getplantsResponse[i].toy_tour_plnt_id) {
            localStorage.setItem("location",JSON.stringify(getplantsResponse[i]))
          }
        }
      }
      this.appService.location = JSON.parse(localStorage.getItem('location'))
      console.log("this.appService.location in If", this.appService.location);
      this.checkitem = true;
    }

    else{
      this.appService.location = JSON.parse(localStorage.getItem('location'));
      console.log("location json in age-verfn",this.appService.location);
      if (localStorage.getItem('age') != 'true') {
        this.checkitem = false;
      }
      else{
        this.checkitem = true;
      }
    }

    if(this.appService.location != null){
    this.ageVerificationDesc = this.appService.location.age_verification_desc
    }
     
    
  }

  navigatetoAccessibility(){
    this.appService.tourObj.ageVerification=this.checkitem;
    localStorage.setItem('age',"true");
    this.router.navigateByUrl('/accessibility');


  }


  navigatetogrpSize(){
    let details = JSON.parse(localStorage.getItem('location'))
    let queryParams = {
      plantId: details.toy_tour_plnt_id
    }
    this.router.navigate(['/group-size'],{queryParams:queryParams});
  }

}
